'use client';

import Image from 'next/image';

import cn from 'classnames';
import get from 'lodash/get';
import { useIntersectionObserver } from 'usehooks-ts';

import Anchor from '@publicImages/svg/anchor.svg?icon';
import Location from '@publicImages/svg/location.svg?icon';
import Ship from '@publicImages/svg/ship.svg?icon';

import type { Package } from '@/infra/types/voyageInfo/package';

import UIResource from '@/components/UIResource';
import WithFrenchContent from '@/components/WithFrenchContent';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { formatDate } from '@/helpers/util/dateUtil';
import { useAppSelector } from '@/store';

import classes from './ViewMoreDates.module.scss';

const formatHumanReadableList = (originalList: string[] = []) => {
  const list = [...new Set(originalList)];
  return list.concat(list.splice(-2, 2).join(' & ')).join(', ');
};

export const HeaderWithImage = ({ id, packageData }: { id?: string; packageData: Package }) => {
  const { landscapeHero, packageName } = packageData;

  const [titleRef, isTitleVisible] = useIntersectionObserver({ initialIsIntersecting: true });
  const isHeaderSticky = !isTitleVisible;

  return (
    <>
      <div className={classes.headerImage}>
        <Image alt={landscapeHero?.alt} fill src={landscapeHero?.src} />
      </div>
      <div className={cn(classes.titleWithImage, isHeaderSticky && classes.hide)} ref={titleRef}>
        <WithFrenchContent className={classes.content} id={id} node="h2" text={packageName} />
      </div>
      <div aria-hidden className={cn(classes.titleWithImageSticky, isHeaderSticky && classes.show)}>
        <h2 className={classes.content}>{packageName}</h2>
      </div>
    </>
  );
};

export const Header = () => {
  const [titleRef, isTitleVisible] = useIntersectionObserver({ initialIsIntersecting: true, threshold: 0.5 });
  const isHeaderSticky = !isTitleVisible;

  return (
    <>
      <div className={cn(classes.title, isHeaderSticky && classes.hide)} ref={titleRef}>
        <UIResource id="DatesModule.heading" />
      </div>
      <div aria-hidden className={cn(classes.title, classes.titleSticky, isHeaderSticky && classes.show)}>
        <UIResource id="DatesModule.heading" />
      </div>
    </>
  );
};

export const PackageDescription = ({
  packageData,
  ...props
}: { packageData: Package } & React.ComponentPropsWithoutRef<'div'>) => (
  <div className={classes.description} {...props}>
    <div>
      <Ship />
      <span>
        <UIResource
          id="itineraryOverlay.nightsShipName"
          values={{
            N: packageData.duration ?? 5,
            shipName: formatHumanReadableList(packageData.sailingList?.map((e) => e.ship.name)),
          }}
        />
      </span>
    </div>
    <div>
      <Location />
      <span>
        <UIResource
          id="itineraryOverlay.tripTypePort.text"
          values={{
            country: get(packageData, 'sailingList.0.ports.0.countryName', ''), // Need to change correctly
            departurePort: get(packageData, 'sailingList.0.ports.0.name', ''),
            tripType: get(packageData, 'tripType', ''),
          }}
        />
      </span>
    </div>
    <div>
      <Anchor />
      <span>{formatHumanReadableList(packageData.sailingList?.[0]?.ports.map(({ name }) => name))}</span>
    </div>
  </div>
);

const format = 'MMMM yyyy';
export const DatesLabel = ({ alignCenter = false, packageData }: { alignCenter?: boolean; packageData: Package }) => {
  const { sailingList } = packageData;
  const fromDate = formatDate(useAppSelector(selectFromDate), format);
  const toDate = formatDate(useAppSelector(selectToDate), format);

  return (
    <div className={cn(classes.totalSailingsCount, alignCenter && classes.alignCenter)}>
      <UIResource
        id="DatesModule.SailingSummary.label"
        values={{
          N: sailingList?.length,
          startDateEndDate: (
            <span className={classes.startDateEndDate}>
              {fromDate}
              {' - '}
              {toDate}
            </span>
          ),
        }}
      />
    </div>
  );
};
