'use client';

import React from 'react';

import cn from 'classnames';
import { Drawer as Primitive } from 'vaul';

import CrossIcon from '@publicImages/svg/close-without-bg.svg?static';

import classes from './Drawer.module.scss';

export const Root = ({ shouldScaleBackground = true, ...props }: React.ComponentProps<typeof Primitive.Root>) => (
  <Primitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);
Root.displayName = 'Drawer';

export const Content = React.forwardRef<
  React.ElementRef<typeof Primitive.Content>,
  React.ComponentPropsWithoutRef<typeof Primitive.Content> & { closeSlot?: React.ReactNode }
>(({ children, className, closeSlot, ...props }, ref) => (
  <Primitive.Portal>
    <Primitive.Overlay className={classes.overlay} />
    <Primitive.Content className={cn(classes.content, className)} ref={ref} {...props}>
      {closeSlot ? (
        closeSlot
      ) : (
        <Primitive.Close aria-label="Close" autoFocus className={classes.close}>
          <CrossIcon />
        </Primitive.Close>
      )}
      <div className={classes.scrollable}>{children}</div>
    </Primitive.Content>
  </Primitive.Portal>
));
Content.displayName = 'DrawerContent';

export const Trigger = Primitive.Trigger;
export const Title = Primitive.Title;
export const Description = Primitive.Description;
